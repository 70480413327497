function initializeNotifications() {
	var config = { messagingSenderId: App.firebaseConfig.senderId };
	firebase.initializeApp(config);
	
	if ('Notification' in window) {
	    var messaging = firebase.messaging();
	    messaging.usePublicVapidKey(App.firebaseConfig.publicVapidKey);
		messaging.onMessage((payload) => {
			setTimeout(() => {
				navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration) => {
					registration.getNotifications({tag: payload.data.sid}).then((notifications) => {
						if (notifications.length == 0) {
							registration.showNotification(payload.data.title, { body: payload.data.body, icon: "/static/theme/img/logo256x256.png", tag: payload.data.sid});
						}
					});
				});
			}, 1000*Math.random());
	    });
		
		messaging.onTokenRefresh(function() {
			messaging.getToken().then(function(refreshedToken) {
				if (refreshedToken) {
					console.log('Token refreshed ', refreshedToken);
					App.makePost('/aj/alerts/bpush', {token: refreshedToken});
				}
			})
		});
	}
}

initializeNotifications();